import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Homepage from './pages/homepage/homepage';
import Product_main from './pages/product_main/product_main';
import Product_automation from './pages/product_automation/product_automation';
import Product_intelligence from './pages/product_intelligence/product_intelligence';
import Product_digital1 from './pages/product_digital1/product_digital1';
import Product_digital2 from './pages/product_digital2/product_digital2';
import Product_digital3 from './pages/product_digital3/product_digital3';
import Product_digital4 from './pages/product_digital4/product_digital4';
import Client from './pages/client/client';
import About_intro from './pages/about_intro/about_intro';
import About_invite from './pages/about_invite/about_invite';
import About_contact from './pages/about_contact/about_contact';
import Product_customize1 from './pages/product_customize1/product_customize1';
import Product_customize2 from './pages/product_customize2/product_customize2';
import Cases_device from './pages/cases_device/cases_device';
import Cases_login from './pages/cases_login/cases_login';
import Cases_regist from './pages/cases_regist/cases_regist';
import Cases_internet from './pages/cases_internet/cases_internet';
import Cases_digital from './pages/cases_digital/cases_digital';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path={['/', '/chanpinyufuwu']} component={Chanpinyufuwu} /> */}
        <Route exact path={['/', "/homepage"]} component={Homepage} />
        <Route exact path="/product_main" component={Product_main} />
        <Route exact path="/product_automation" component={Product_automation} />
        <Route exact path="/product_intelligence" component={Product_intelligence} />
        <Route exact path="/product_digital1" component={Product_digital1} />
        <Route exact path="/product_digital2" component={Product_digital2} />
        <Route exact path="/product_digital3" component={Product_digital3} />
        <Route exact path="/product_digital4" component={Product_digital4} />
        <Route exact path="/client" component={Client} />
        <Route exact path="/about_intro" component={About_intro} />
        <Route exact path="/about_invite" component={About_invite} />
        <Route exact path="/about_contact" component={About_contact} />
        <Route exact path="/product_customize1" component={Product_customize1} />
        <Route exact path="/product_customize2" component={Product_customize2} />
        <Route exact path="/cases_device" component={Cases_device} />
        <Route exact path="/cases_login" component={Cases_login} />
        <Route exact path="/cases_regist" component={Cases_regist} />
        <Route exact path="/cases_internet" component={Cases_internet} />
        <Route exact path="/cases_digital" component={Cases_digital} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;