
import React, { Component } from 'react';
import styles from './product_digital3.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Product_digital3 extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '软件定制化',
    code: 2.5,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
          <div className={`flex-col ${styles['group_8']}`}>
            <div className={`flex-col items-center`}>
              <span className={`${styles['text_10']}`}>软件定制化</span>
              <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
            </div>
            <div className={`mt-36 flex-col`}>
              <div className={`flex-col ${styles['section_3']}`}>
                <span className={`self-stretch ${styles['font_3']}`}> 我们拥有丰富的行业经验，可以根据客户需求进行软件的数字化定制：<br /></span>
                <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>a. 基于丰富的制造业经验，快速深入了解用户的生产制造流程，和用户一起起草技术要求书。<br /></span>
                <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>b. 根据用户的生产制造流程、业务逻辑，结合已有架构和模块进行软件设计和研发。</span>
                <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 根据用户建议、使用偏好设计并改进界面。</span>
                <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>d. 最终实现定制化MES，并且超越MES的数字化制造。</span>
              </div>
              <div className={`flex-row justify-between ${styles['section_4']}`}>
                <Link to='/product_digital2' style={{ textDecoration: 'none' }} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                  <span className={`${styles['font_4']}`}>&lt;</span>
                </Link>
                <div className={`flex-row ${styles['equal-division']}`}>
                  <Link to='/product_digital1' style={{ textDecoration: 'none' }} className={`flex-col items-center ${styles['equal-division-item']}`}>
                    <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/数据可视化.png").default} />
                    <span className={`${styles['font_5']} ${styles['text_11']}`}>数据可视化</span>
                    
                  </Link>
                  <Link to='/product_digital2' style={{ textDecoration: 'none' }} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                    <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/数据打通.jpg").default} />
                    <span className={`mt-20 ${styles['font_5']}`}>数据打通</span>
                  </Link>
                  <div className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                    <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/软件定制化.jpeg").default} />
                    <span className={`mt-20 ${styles['font_5']}`}>软件定制化</span>
                    <div className={`shrink-0 ${styles['section_5']}`}></div>
                  </div>
                  <Link to='/product_digital4' style={{ textDecoration: 'none' }} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                    <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/多种行业专家库.png").default} />
                    <span className={`mt-20 ${styles['font_5']}`}>多种行业专家库</span>
                  </Link>
                </div>
                <Link to='/product_digital4' style={{ textDecoration: 'none' }} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                  <span className={`${styles['font_4']}`}>&gt;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Product_digital3;
