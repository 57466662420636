
import React, { Component } from 'react';
import styles from './product_digital4.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Product_digital4 extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '多种行业专家库',
    code: 2.6,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
        <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
        <div className={`flex-col ${styles['group_8']}`}>
          <div className={`flex-col items-center`}>
            <span className={`${styles['text_10']}`}>多种行业专家库</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`mt-36 flex-col`}>
            <div className={`flex-col ${styles['section_3']}`}>
              <span className={`self-stretch ${styles['font_3']}`}> a. 集中各个行业的专家，集中各个学科的人才，为客户解决生产制造过程中遇到的各种问题。<br /></span>
              <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>b. 技术储备包括机械、电子电力、软件、算法、化学、光学、声学等学科，涵盖各种制造业的行业知识。<br /></span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 深刻理解客户对产业升级的需求，通过联合研发满足客户对降成本、增效益、提质量、产品创新、应用创新的高端需求。</span>
            </div>
            <div className={`flex-row justify-between ${styles['section_4']}`}>
              <Link to='/product_digital3' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&lt;</span>
              </Link>
              <div className={`flex-row ${styles['equal-division']}`}>
                <Link to='/product_digital1' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/数据可视化.png").default} />
                  <span className={`${styles['font_5']} ${styles['text_11']}`}>数据可视化</span>
                  
                </Link>
                <Link to='/product_digital2' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/数据打通.jpg").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>数据打通</span>
                </Link>
                <Link to='/product_digital3' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/软件定制化.jpeg").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>软件定制化</span>
                </Link>
                <div className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/多种行业专家库.png").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>多种行业专家库</span>
                  <div className={`shrink-0 ${styles['section_5']}`}></div>
                </div>
              </div>
              <Link to='/product_digital1' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </Layout>
    )
  }
}
    
  export default Product_digital4;
  