import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './cases_regist.module.css';

export default function Cases_regist(props) {
  const history = useHistory();

  const [data, setData] = useState({});

  return (
    <div className={`flex-col justify-start relative ${styles['page']}`}>
      <div className={`${styles['section']}`}></div>
      <div className={`flex-col ${styles['section_2']} ${styles['pos']}`}>
        <div className={`flex-row justify-evenly items-center ${styles['group']}`}>
          <img
            className={`${styles['image']}`}
            src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=6fb5aaeae9033547c9f3139b7b9b5c29.png"
          />
          <img
            className={`${styles['image_2']}`}
            src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=62d885213b4574af8611c24a0f429a3d.png"
          />
        </div>
        <div className={`mt-38 flex-col`}>
          <span className={`self-center ${styles['font']} ${styles['text']}`}>用户注册</span>
          <div className={`flex-col self-stretch ${styles['mt-39']}`}>
            <div className={`flex-col`}>
              <div className={`flex-row items-center ${styles['form-item']}`}>
                <span className={`${styles['font_2']}`}>用户名：</span>
                <div className={`${styles['section_3']} ${styles['input']} ${styles['ml-15']}`}></div>
              </div>
              <div className={`flex-row items-center ${styles['form-item_2']}`}>
                <span className={`${styles['font_2']} ${styles['text_2']}`}>密码：</span>
                <div className={`${styles['section_3']} ${styles['input']} ${styles['ml-31']}`}></div>
              </div>
              <div className={`flex-row items-center ${styles['form-item_2']}`}>
                <span className={`${styles['font_2']}`}>确认密码：</span>
                <div className={`${styles['section_3']} ${styles['input']}`}></div>
              </div>
              <div className={`flex-row items-center ${styles['form-item_2']}`}>
                <span className={`${styles['font_2']} ${styles['text_3']}`}>公司：</span>
                <div className={`ml-30 ${styles['section_3']} ${styles['input']}`}></div>
              </div>
              <div className={`flex-row items-center ${styles['form-item_2']}`}>
                <span className={`${styles['font_2']}`}>姓名：</span>
                <div className={`${styles['section_3']} ${styles['input']} ${styles['ml-31']}`}></div>
              </div>
              <div className={`flex-row items-center ${styles['form-item_2']}`}>
                <span className={`${styles['font_2']} ${styles['text_4']}`}>联系电话：</span>
                <div className={`${styles['section_3']} ${styles['input']}`}></div>
              </div>
              <div className={`flex-row items-center ${styles['form-item_2']}`}>
                <span className={`${styles['font_2']}`}>邮箱：</span>
                <div className={`${styles['section_3']} ${styles['input']} ${styles['ml-31']}`}></div>
              </div>
            </div>
            <div className={`mt-18 flex-col items-start ${styles['group_2']}`}>
              <div className={`flex-col justify-start items-center ${styles['button']}`}>
                <span className={`${styles['font']} ${styles['text_5']}`}>注册</span>
              </div>
              <div className={`flex-col justify-start items-center ${styles['button']} ${styles['mt-13']}`}>
                <span className={`${styles['font']}`}>返回登录</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Cases_regist.defaultProps = {};