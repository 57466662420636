import React, { Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './about_intro.module.css';
import Layout from '../../common/layout';

class About_intro extends Component {
  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '关于-公司简介',
    code: 5.1,
  };

  render() {
    let state = this.state;
    return (
      <Layout {...state}>
        <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/about/公司简介.png").default}
          />
        <div className={`flex-col items-center ${styles['group_8']}`}>
          
          <span className={`${styles['text_10']}`}>公司简介</span>
          <div className={`${styles['section_2']} ${styles['mt-2-5']}`}></div>
        </div>
        <div className={`flex-col ${styles['group_9']}`}>
          <div className={`flex-col justify-start ${styles['text-wrapper']}`}>
            <span className={`${styles['font_3']} ${styles['text_11']}`}>              
              ·专注于制造业产业升级。<br/>
              ·主要客户为制造业大中小型企业，具有跨行业特征。<br/>
              ·技术储备包括机械、电子、软件、云存储及计算、大数据、算法、光学和声学等。<br/>
              ·以上技术集成，实现工业互联网、数字化制造。

            </span>
          </div>
        </div>
      </Layout>
    )
  }

}

export default About_intro;