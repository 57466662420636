import React, { useState, useEffect, Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './cases_digital.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';


class Cases_digital extends Component {

  state = {
    name: '产品-数字化',
    code: '3.3',
  }

  render() {
    let state = this.state;

    const digitalList = [
      {
        pictureURL: require("../../pictures/cases/default.png").default,
        name: '石油生产过程监测平台',
        docPath: '../../pictures/cases/default.png'
      },
      {
        pictureURL: require("../../pictures/cases/default.png").default,
        name: '测试',
        docPath: '../../pictures/cases/default.png'
      },
    ]

    const chunkSize = 3;

    const chunkedArray = Array.from({ length: Math.ceil(digitalList.length / chunkSize) }, (_, index) =>
      digitalList.slice(index * chunkSize, (index + 1) * chunkSize)
    );

    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/cases/产品素材.png").default}
          />
          <div className={`flex-row justify-between ${styles['group_8']}`}>
            <div className={`flex-col justify-start ${styles['text-wrapper']}`}>
              <span className={`${styles['text_11']}`}>产品案例</span>
            </div>
            <div className={`flex-row items-center ${styles['section_2']}`}>
              <span className={`${styles['font_3']} ${styles['text_11']}`}>
                <input type='search' width={'15rem'} placeholder='输入查找对象' />
              </span>
              <button className={`${styles['button_search']}`}>搜索</button>

            </div>
          </div>
          <div className={`flex-row ${styles['section_3']}`}>
            <div className={`flex-col shrink-0 self-start`}>
              <div className={`flex-row items-center ${styles['section_4']}`}>
                <Link to='/cases_device' style={{ textDecoration: 'none' }}>
                  <span className={`${styles['font_3']} ${styles['text_13']}`}>设备</span>
                </Link>
                <span className={`${styles['font_4']} ${styles['ml-161']}`}>&gt;</span>
              </div>
              <div className={`flex-row items-center ${styles['section_4']}`}>
                <Link to='/cases_internet' style={{ textDecoration: 'none' }}>
                  <span className={`${styles['font_3']} ${styles['text_13']}`}>物联网</span>
                </Link>
                <span className={`${styles['font_4']} ${styles['ml-137']}`}>&gt;</span>
              </div>
              <div className={`flex-row items-center ${styles['section_7']}`}>
                <div className={`flex-col justify-start shrink-0 ${styles['text-wrapper_2']}`}>
                  <span className={`${styles['font_3']} ${styles['text_15']}`}>数字化</span>
                </div>
                <span className={`${styles['font_4']} ${styles['ml-128']}`}>&gt;</span>
              </div>
            </div>
            <div className={`flex-col justify-start items-start flex-1 ${styles['section_5']}`}>
              {chunkedArray.map((chunk, index) => (
                <div className={`flex-row`}>
                  {chunk.map((item, i) => (
                    <div className={`flex-col ${styles['section_6']}`}>
                      <img
                        className={`shrink-0 ${styles['image_6']}`}
                        src={item.pictureURL}
                      />
                      <div className={`flex-col items-center ${styles['mt-47']}`}>
                        <span className={`${styles['text_14']}`}>{item.name}</span>
                        <div className={`flex-col justify-start items-center ${styles['button']} ${styles['mt-27']}`}>
                          <a href={item.docPath} style={{textDecoration:'none'}} className={`${styles['font_3']} ${styles['text_16']}`}>下载案例</a>
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              ))}


            </div>
          </div>
        </div>

      </Layout >
    )
  }


}

export default Cases_digital;