
import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './product_intelligence.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Product_intelligence extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '智能化',
    code: 2.2,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
          <div className={`flex-col ${styles['group_8']}`}>
            <div className={`flex-col items-center`}>
              <span className={`${styles['text_10']}`}>智能化升级</span>
              <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
            </div>
            <div className={`mt-36 flex-col`}>
              <div className={`flex-col ${styles['section_3']}`}>
                <span className={`self-stretch ${styles['font_3']}`}>智能化升级是一种提高设备工作效率的有效手段，本公司从一下三个方面对客户设备进行智能化升级：<br /></span>
                <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>a. 在设备层面，根据客户要求，将产线数据进行处理，经过分析计算，直接反馈到产线进行自动化操作。<br /></span>
                <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>b. 在执行层面，分析生产制造数据得出结果，指导执行人员的操作。</span>
                <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 在决策层面，统计分析大数据，提供决策依据。</span>
              </div>
              <div className={`flex-row justify-between ${styles['section_4']}`}>
                <Link to='/product_automation' style={{ textDecoration: 'none' }} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                  <span className={`${styles['font_4']}`}>&lt;</span>
                </Link>
                <div className={`flex-row ${styles['equal-division']}`}>
                  <Link to='/product_automation' style={{ textDecoration: 'none' }} className={`flex-col items-center ${styles['equal-division-item']}`}>
                    <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/自动化.jpeg").default} />
                    <span className={`${styles['font_5']} ${styles['text_11']}`}>自动化改造与升级</span>

                  </Link>
                  <div className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                    <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/智能化.jpg").default} />
                    <span className={`mt-20 ${styles['font_5']}`}>智能化升级</span>
                    <div className={`shrink-0 ${styles['section_5']}`}></div>
                  </div>
                </div>
                <Link to='/product_automation' style={{ textDecoration: 'none' }} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                  <span className={`${styles['font_4']}`}>&gt;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Product_intelligence;
