import React, { Component } from 'react';
import styles from './about_contact.module.css';
import Layout from '../../common/layout';

class About_contact extends Component {

  state = {
    name: '关于-联系方式',
    code: 5.3,
  };

  render() {
    let state = this.state;
    return (
      <Layout {...state}>
        <img
          className={`${styles['image_4']}`}
          src={require("../../pictures/about/联系我们.png").default}
        />

        <div className={`flex-col items-center ${styles['group_8']}`}>
          <span className={`${styles['text_10']}`}>联系我们</span>
          <div className={`${styles['section_2']} ${styles['mt-2-5']}`}></div>
        </div>
        <div className={`flex-col ${styles['group_9']}`}>
          <div className={`flex-col items-start ${styles['group_10']}`}>
            <span className={`${styles['font_3']}`}>公司地址：上海市普陀区陕西北路1388号银座企业中心 1816室</span>
            <span className={`${styles['font_3']} ${styles['text_11']} ${styles['mt-12-5']}`}>电话：021-61234357/61234358</span>
            <span className={`${styles['font_3']} ${styles['mt-12-5']}`}>邮箱：
			<a href="mailto:hr@wuzhong-tech.com"><span className={`${styles['font_3']}`}>hr@wuzhong-tech.com</span></a></span>
          </div>
        </div>

      </Layout>
    )
  }

}

export default About_contact;