import React, { useState, useEffect, Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './client.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';




class Client extends Component {

  state = {
    name: '客户',
    code: 4,
    items: [null, null, null]
  }

  render() {

    let state = this.state;

    const list_items = [
      {
        picture_src: require("../../pictures/client/衡所华威.png").default,
        company: '衡所华威电子有限公司',
        intro: `合作项目：设备物联网控制\n包含云端工业物联网，凝胶时间测量台，柱状无接触光学测量台等产品的合作`,
        website: 'https://hysolhuawei.wuzhong-cloud.com/#/login',
      },
      {
        picture_src: require("../../pictures/client/石油客户.png").default,
        company: '其他2',
        intro: '测试2',
        website: 'https://hysolhuawei.wuzhong-cloud.com/#/login',
      },
      {
        picture_src: require("../../pictures/client/衡所华威.png").default,
        company: '其他3',
        intro: '测试3',
        website: 'https://hysolhuawei.wuzhong-cloud.com/#/login',
      },
    ];


    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/client/客户素材.png").default}
          />
          <div className={`mt-46 flex-col`}>
            <div className={`flex-col ${styles['group_8']}`}>
              <div className={`flex-row justify-between`}>
                <div className={`flex-col justify-start ${styles['text-wrapper']}`}>
                  <span className={`${styles['text_10']}`}>客户登录入口</span>
                </div>
                <div className={`flex-row items-center ${styles['section_2']}`}>
                  <span className={`${styles['font_3']} ${styles['text_11']}`}>
                    <input type='search' width={'15rem'} placeholder='输入查找对象' />
                  </span>
                  <button className={`${styles['button_search']}`}>搜索</button>
                  
                </div>
              </div>
              <div className={`flex-col ${styles['list']} ${styles['mt-31']}`}>
                {state.items.map((item, index) => (
                  <div className={`flex-col justify-start items-start ${styles['list-item']}`} key={index}>
                    <div className={`flex-row items-center relative ${styles['section_3']}`}>
                      <img
                        className={`${styles['image_6']}`}
                        src={list_items[index].picture_src}
                      />
                      <div className={`flex-col items-start ${styles['group_9']} ${styles['ml-142']}`}>
                        <span className={`${styles['font_4']} ${styles['text_12']}`}>{list_items[index].company}</span>
                        <span className={`${styles['font_5']} ${styles['text_13']}`}>{list_items[index].intro}</span>
                        <div
                          className={`flex-col justify-start items-center ${styles['text-wrapper_2']} ${styles['button']}`}
                        >
                          <a href={list_items[index].website} className={`${styles['link_style']}`}>
                          <span className={`${styles['font_3']}`}>跳转登录</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  // const history = useHistory();

  // const [data, setData] = useState({
  //   items: [null, null, null],
  // });

  // return (
  //   <div className={`flex-col ${styles['page']}`}>
  //     <div className={`flex-row justify-between items-center ${styles['header']}`}>
  //       <div className={`flex-row items-center`}>
  //         <img
  //           className={`${styles['image']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=b030080fb52becab53f8e4588f46bbb2.png"
  //         />
  //         <img
  //           className={`${styles['image_2']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=13f95824b26ebe209983eddfcd39dd44.png"
  //         />
  //       </div>
  //       <div className={`flex-row items-center ${styles['group']}`}>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_2']}`}>
  //             <span className={`${styles['font']} ${styles['text']}`}>主页</span>
  //             <span className={`${styles['font_2']} ${styles['text_5']}`}>HOMEPAGE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_3']}`}>
  //             <span className={`${styles['font']} ${styles['text_2']}`}>服务</span>
  //             <span className={`${styles['font_2']} ${styles['text_6']}`}>SERVICE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_4']}`}>
  //             <span className={`${styles['font']} ${styles['text_3']}`}>产品 </span>
  //             <span className={`${styles['font_2']} ${styles['text_7']}`}>PRODUCT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_5']}`}>
  //             <span className={`${styles['font']} ${styles['text_4']}`}>客户</span>
  //             <span className={`${styles['font_2']} ${styles['text_8']}`}>CLIENT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_6']}`}>
  //             <span className={`${styles['font']}`}>关于</span>
  //             <span className={`${styles['font_2']} ${styles['text_9']}`}>ABOUT</span>
  //           </div>
  //         </div>
  //         <img
  //           className={`${styles['image_3']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=0b92780d7e5c0dd988a7a1cc155133e7.png"
  //         />
  //       </div>
  //     </div>
  // <div className={`flex-col flex-1 ${styles['group_7']}`}>
  //   <img
  //     className={`${styles['image_4']}`}
  //     src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=217a1fb110969acb6e81054c49a11def.png"
  //   />
  //   <div className={`mt-46 flex-col`}>
  //     <div className={`flex-col ${styles['group_8']}`}>
  //       <div className={`flex-row justify-between`}>
  //         <div className={`flex-col justify-start ${styles['text-wrapper']}`}>
  //           <span className={`${styles['text_10']}`}>客户登录入口</span>
  //         </div>
  //         <div className={`flex-row items-center ${styles['section_2']}`}>
  //           <span className={`${styles['font_3']} ${styles['text_11']}`}>输入查找对象</span>
  //           <img
  //             className={`ml-48 shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=229f52dfe9e21788af257003dd811a71.png"
  //           />
  //         </div>
  //       </div>
  //       <div className={`flex-col ${styles['list']} ${styles['mt-31']}`}>
  //         {data.items.map((item, index) => (
  //           <div className={`flex-col justify-start items-start ${styles['list-item']}`} key={index}>
  //             <div className={`flex-row items-center relative ${styles['section_3']}`}>
  //               <img
  //                 className={`${styles['image_6']}`}
  //                 src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=f211f2644a40c7c2486560db3569c17e.png"
  //               />
  //               <div className={`flex-col items-start ${styles['group_9']} ${styles['ml-142']}`}>
  //                 <span className={`${styles['font_4']} ${styles['text_12']}`}>衡所华威电子有限公司</span>
  //                 <span className={`${styles['font_5']} ${styles['text_13']}`}>设备物联网控制</span>
  //                 <div
  //                   className={`flex-col justify-start items-center ${styles['text-wrapper_2']} ${styles['button']}`}
  //                 >
  //                   <span className={`${styles['font_3']}`}>跳转登录</span>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //         <div className={`flex-row justify-center relative ${styles['section_6']}`}>
  //           <div className={`flex-col items-center self-center ${styles['pos']}`}>
  //             <img
  //               className={`${styles['image_7']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=2447f5886400716b50878cf72a75b67e.png"
  //             />
  //             <img
  //               className={`${styles['image_8']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=7a20a788df1f233238a4c5e76ecf20d1.png"
  //             />
  //           </div>
  //           <div className={`flex-col self-start ${styles['group_10']}`}>
  //             <span className={`self-stretch ${styles['font_6']}`}>
  //               公司地址：上海市普陀区陕西北路1388号银座企业中心 1816室
  //               <br />
  //             </span>
  //             <span className={`self-start ${styles['font_6']} ${styles['text_19']} ${styles['mt-29']}`}>
  //               电话：86-XXXXXXXXXX
  //             </span>
  //             <span className={`self-start ${styles['font_6']} ${styles['mt-29']}`}>传真：XXXXXXXX</span>
  //           </div>
  //           <div
  //             className={`flex-col justify-start items-center self-start ${styles['text-wrapper_3']} ${styles['pos_2']}`}
  //           >
  //             <span className={`${styles['font_3']} ${styles['text_18']}`}>二维码</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['text-wrapper_4']}`}>
  //           <span className={`${styles['font_3']} ${styles['text_20']}`}><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021002642号-2</a></span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Client;