
import React, { Component } from 'react';
import styles from './product_customize2.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class product_customize2 extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '行业',
    code: 2.8,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
        <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
        <div className={`flex-col ${styles['group_8']}`}>
          <div className={`flex-col items-center`}>
            <span className={`${styles['text_10']}`}>行业</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`mt-36 flex-col`}>
            <div className={`flex-col ${styles['section_3']}`}>
              <span className={`self-stretch ${styles['font_3']}`}> a. 基于技术储备包括机械、电子电力、软件、算法、化学、光学、声学等学科，涵盖各种制造业的行业知识，服务多种行业。<br /></span>
              <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>b. 基于已有的架构和模块，基于技术人员联合，基于丰富的行业经验，根据客户的要求，快速做出定制化方案。<br /></span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 依靠专业技术团队和施工团队，快速实施项目。</span>
            </div>
            <div className={`flex-row justify-between ${styles['section_4']}`}>
              <Link to='/product_customize1' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&lt;</span>
              </Link>
              <div className={`flex-row ${styles['equal-division']}`}>
                <Link to='/product_customize1' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/协作.jpeg").default} />
                  <span className={`${styles['font_5']} ${styles['text_11']}`}>协作</span>
                  
                </Link>
                <div className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/行业.png").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>行业</span>
                  <div className={`shrink-0 ${styles['section_5']}`}></div>
                </div>
                
              </div>
              <Link to='/product_customize1' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </Layout>
    )
  }
}
    
  export default product_customize2;
  