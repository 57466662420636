
import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './product_automation.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Product_automation extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '自动化',
    code: 2.1,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
        <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
        <div className={`flex-col ${styles['group_8']}`}>
          <div className={`flex-col items-center`}>
            <span className={`${styles['text_10']}`}>自动化改造与升级</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`mt-36 flex-col`}>
            <div className={`flex-col ${styles['section_3']}`}>
              <span className={`self-stretch ${styles['font_3']}`}>a. 自动化改造主要针对客户已有的自动化水平较低的生产设备。自动化升级主要针对客户已有的自动化水平较高的生产设备。自动化改造和升级是在现有的自动化基础上，进一步完善，进一步提高，为数字化制造打好物质基础。<br /></span>
              <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>b. 根据客户要求，为客户提供定制自动化方案，一般来讲，方案会集成机械、电子电力、软硬件等，使得客户的生产效率和生产质量得到提高。<br /></span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 以专家知识库为基础，为客户提供自动化方案建议，实现降成本、增效益、提质量、产品创新、应用创新。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>d. 为产线上需要测量和控制的地方提供定制设备。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>e. 利用专业的机械、电子电力以及软硬件知识和技术解决客户生产制造过程中的痛点、难点问题。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>f. 采集自动化改造和升级后的生产数据并传送到云端数字化系统。</span>
            </div>
            <div className={`flex-row justify-between ${styles['section_4']}`}>
              <Link to='/product_intelligence' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&lt;</span>
              </Link>
              <div className={`flex-row ${styles['equal-division']}`}>
                <div className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/自动化.jpeg").default} />
                  <span className={`${styles['font_5']} ${styles['text_11']}`}>自动化改造与升级</span>
                  <div className={`shrink-0 ${styles['section_5']}`}></div>
                </div>
                <Link to='/product_intelligence' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/智能化.jpg").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>智能化升级</span>
                </Link>
              </div>
              <Link to='/product_intelligence' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </Layout>
    )
  }

  // return (
  //   <div className={`flex-col ${styles['page']}`}>
  //     <div className={`flex-row justify-between items-center ${styles['header']}`}>
  //       <div className={`flex-row items-center`}>
  //         <img className={`${styles['image']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=b030080fb52becab53f8e4588f46bbb2.png" />
  //         <img className={`${styles['image_2']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=13f95824b26ebe209983eddfcd39dd44.png" />
  //       </div>
  //       <div className={`flex-row items-center ${styles['group']}`}>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_2']}`}>
  //             <span className={`${styles['font']} ${styles['text']}`}>主页</span>
  //             <span className={`${styles['font_2']} ${styles['text_5']}`}>HOMEPAGE</span></div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_3']}`}>
  //             <span className={`${styles['font']} ${styles['text_2']}`}>服务</span>
  //             <span className={`${styles['font_2']} ${styles['text_6']}`}>SERVICE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_4']}`}>
  //             <span className={`${styles['font']} ${styles['text_3']}`}>产品 </span>
  //             <span className={`${styles['font_2']} ${styles['text_7']}`}>PRODUCT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_5']}`}>
  //             <span className={`${styles['font']} ${styles['text_4']}`}>客户</span>
  //             <span className={`${styles['font_2']} ${styles['text_8']}`}>CLIENT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_6']}`}>
  //             <span className={`${styles['font']}`}>关于</span>
  //             <span className={`${styles['font_2']} ${styles['text_9']}`}>ABOUT</span>
  //           </div>
  //         </div>
  //         <img className={`${styles['image_3']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=0b92780d7e5c0dd988a7a1cc155133e7.png" />
  //       </div>
  //     </div>


      // <div className={`flex-col flex-1 ${styles['group_7']}`}>
      //   <img className={`${styles['image_4']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=91486d99e530a4af35f50851818c3988.png" />
      //   <div className={`flex-col ${styles['group_8']}`}>
      //     <div className={`flex-col items-center`}>
      //       <span className={`${styles['text_10']}`}>自动化改造与升级</span>
      //       <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
      //     </div>
      //     <div className={`mt-36 flex-col`}>
      //       <div className={`flex-col ${styles['section_3']}`}>
      //         <span className={`self-stretch ${styles['font_3']}`}>a. 自动化改造主要针对客户已有的自动化水平较低的生产设备。自动化升级主要针对客户已有的自动化水平较高的生产设备。自动化改造和升级是在现有的自动化基础上，进一步完善，进一步提高，为数字化制造打好物质基础。<br /></span>
      //         <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>b. 根据客户要求，为客户提供定制自动化方案，一般来讲，方案会集成机械、电子电力、软硬件等，使得客户的生产效率和生产质量得到提高。<br /></span>
      //         <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 以专家知识库为基础，为客户提供自动化方案建议，实现降成本、增效益、提质量、产品创新、应用创新。</span>
      //         <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>d. 为产线上需要测量和控制的地方提供定制设备。</span>
      //         <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>e. 利用专业的机械、电子电力以及软硬件知识和技术解决客户生产制造过程中的痛点、难点问题。</span>
      //         <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>f. 采集自动化改造和升级后的生产数据并传送到云端数字化系统。</span>
      //       </div>
      //       <div className={`flex-row justify-between ${styles['section_4']}`}>
      //         <div className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
      //           <span className={`${styles['font_4']}`}>&lt</span>
      //         </div>
      //         <div className={`flex-row ${styles['equal-division']}`}>
      //           <div className={`flex-col items-center ${styles['equal-division-item']}`}>
      //             <img className={`shrink-0 ${styles['image_5']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png" />
      //             <span className={`${styles['font_5']} ${styles['text_11']}`}>自动化改造与升级</span>
      //             <div className={`shrink-0 ${styles['section_5']}`}></div>
      //           </div>
      //           <div className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
      //             <img className={`shrink-0 ${styles['image_5']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png" />
      //             <span className={`mt-20 ${styles['font_5']}`}>智能化升级</span>
      //           </div>
      //         </div>
      //         <div className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
      //           <span className={`${styles['font_4']}`}>&gt</span>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
  //       <div className={`flex-col ${styles['group_9']}`}>
  //         <div className={`flex-row justify-center relative ${styles['section_6']}`}>
  //           <div className={`flex-col items-center self-center ${styles['pos']}`}>
  //             <img className={`${styles['image_6']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=2447f5886400716b50878cf72a75b67e.png" />
  //             <img className={`${styles['image_7']}`} src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=7a20a788df1f233238a4c5e76ecf20d1.png" />
  //           </div>
  //           <div className={`flex-col self-start ${styles['group_10']}`}>
  //             <span className={`self-stretch ${styles['font_3']}`}>公司地址：上海市普陀区陕西北路1388号银座企业中心 1816室<br /></span>
  //             <span className={`self-start ${styles['font_3']} ${styles['text_13']} ${styles['mt-29']}`}>电话：86-XXXXXXXXXX</span>
  //             <span className={`self-start ${styles['font_3']} ${styles['mt-29']}`}>传真：XXXXXXXX</span></div>
  //           <div className={`flex-col justify-start items-center self-start ${styles['text-wrapper_2']} ${styles['pos_2']}`}>
  //             <span className={`${styles['font_3']} ${styles['text_12']}`}>二维码</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['text-wrapper_3']}`}><span className={`${styles['text_14']}`}><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021002642号-2</a></span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>);
}

export default Product_automation;
