import React, { useState, useEffect, Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './product_main.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Product_main extends Component {
  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '服务',
    code: 2,
  };

  render() {
    let state = this.state;
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/product_main/服务素材.jpeg").default}
          />
          <div className={`flex-col items-start ${styles['group_8']}`}>
            <span className={`${styles['text_10']}`}>产品与服务</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`flex-col ${styles['group_9']}`}>
            <div className={`flex-row justify-center relative ${styles['section_3']}`}>
              <span className={`self-start ${styles['font_3']} ${styles['text_11']} ${styles['pos']}`}>
                自动化/智能化升级
              </span>
              <div className={`flex-row ${styles['equal-division']}`}>
                <Link to={{
                  pathname: '/product_automation',
                  state: 'automation',
                  }} style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/自动化.jpeg").default}
                  />
                  <span className={`${styles['font_4']} ${styles['text_12']} ${styles['mt-21']}`}>自动化改造与升级</span>
                </Link>
                <a href='/product_intelligence' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/智能化.jpg").default}
                  />
                  <span className={`mt-20 ${styles['font_4']} ${styles['text_13']}`}>智能化升级</span>
                </a>
              </div>
            </div>
            <div className={`flex-row ${styles['section_4']}`}>
              <span className={`self-start ${styles['font_3']} ${styles['text_14']}`}>数字化改造</span>
              <div className={`flex-row ${styles['equal-division']} ${styles['ml-125']}`}>
                <a href='/product_digital1' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['section_5']} ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/数据可视化.png").default}
                  />
                  <span className={`mt-20 ${styles['font_4']}`}>数据可视化</span>
                </a>
                <a href='/product_digital2' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['section_5']} ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/数据打通.jpg").default}
                  />
                  <span className={`mt-20 ${styles['font_4']}`}>数据打通</span>
                </a>
                <a href='/product_digital3' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['section_5']} ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/软件定制化.jpeg").default}
                  />
                  <span className={`mt-20 ${styles['font_4']}`}>软件定制化</span>
                </a>
                <a href='/product_digital4' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['section_5']} ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/多种行业专家库.png").default}
                  />
                  <span className={`mt-20 ${styles['font_4']}`}>多种行业专家库</span>
                </a>
              </div>
            </div>
            <div className={`flex-row justify-center relative ${styles['section_6']}`}>
              <span className={`self-start ${styles['font_3']} ${styles['pos_2']}`}>定制化服务</span>
              <div className={`flex-row ${styles['equal-division']}`}>
                <a href='/product_customize1' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/协作.jpeg").default}
                  />
                  <span className={`mt-20 ${styles['font_4']} ${styles['text_15']}`}>协作</span>
                </a>
                <a href='/product_customize2' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item']}`}>
                  <img
                    className={`shrink-0 ${styles['image_5']}`}
                    src={require("../../pictures/product_main/行业.png").default}
                  />
                  <span className={`${styles['font_4']} ${styles['text_16']} ${styles['mt-21']}`}>行业</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  // return (
  //   <div className={`flex-col ${styles['page']}`}>
  //     <div className={`flex-row justify-between items-center ${styles['header']}`}>
  //       <div className={`flex-row items-center`}>
  //         <img
  //           className={`${styles['image']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=b030080fb52becab53f8e4588f46bbb2.png"
  //         />
  //         <img
  //           className={`${styles['image_2']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=13f95824b26ebe209983eddfcd39dd44.png"
  //         />
  //       </div>
  //       <div className={`flex-row items-center ${styles['group']}`}>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_2']}`}>
  //             <span className={`${styles['font']} ${styles['text']}`}>主页</span>
  //             <span className={`${styles['font_2']} ${styles['text_5']}`}>HOMEPAGE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_3']}`}>
  //             <span className={`${styles['font']} ${styles['text_2']}`}>服务</span>
  //             <span className={`${styles['font_2']} ${styles['text_6']}`}>SERVICE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_4']}`}>
  //             <span className={`${styles['font']} ${styles['text_3']}`}>产品 </span>
  //             <span className={`${styles['font_2']} ${styles['text_7']}`}>PRODUCT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_5']}`}>
  //             <span className={`${styles['font']} ${styles['text_4']}`}>客户</span>
  //             <span className={`${styles['font_2']} ${styles['text_8']}`}>CLIENT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_6']}`}>
  //             <span className={`${styles['font']}`}>关于</span>
  //             <span className={`${styles['font_2']} ${styles['text_9']}`}>ABOUT</span>
  //           </div>
  //         </div>
  //         <img
  //           className={`${styles['image_3']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=0b92780d7e5c0dd988a7a1cc155133e7.png"
  //         />
  //       </div>
  //     </div>
  // <div className={`flex-col flex-1 ${styles['group_7']}`}>
  //   <img
  //     className={`${styles['image_4']}`}
  //     src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=91486d99e530a4af35f50851818c3988.png"
  //   />
  //   <div className={`flex-col items-start ${styles['group_8']}`}>
  //     <span className={`${styles['text_10']}`}>产品与服务</span>
  //     <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
  //   </div>
  //   <div className={`flex-col ${styles['group_9']}`}>
  //     <div className={`flex-row justify-center relative ${styles['section_3']}`}>
  //       <span className={`self-start ${styles['font_3']} ${styles['text_11']} ${styles['pos']}`}>
  //         自动化/智能化升级
  //       </span>
  //       <div className={`flex-row ${styles['equal-division']}`}>
  //         <div className={`flex-col items-center ${styles['equal-division-item']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`${styles['font_4']} ${styles['text_12']} ${styles['mt-21']}`}>自动化改造与升级</span>
  //         </div>
  //         <div className={`ml-36 flex-col items-center ${styles['equal-division-item']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`mt-20 ${styles['font_4']} ${styles['text_13']}`}>智能化升级</span>
  //         </div>
  //       </div>
  //     </div>
  //     <div className={`flex-row ${styles['section_4']}`}>
  //       <span className={`self-start ${styles['font_3']} ${styles['text_14']}`}>数字化改造</span>
  //       <div className={`flex-row ${styles['equal-division_2']} ${styles['ml-125']}`}>
  //         <div className={`flex-col items-center ${styles['section_5']} ${styles['equal-division-item_2']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`mt-20 ${styles['font_4']}`}>数据可视化</span>
  //         </div>
  //         <div className={`ml-36 flex-col items-center ${styles['section_5']} ${styles['equal-division-item_2']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`mt-20 ${styles['font_4']}`}>数据打通</span>
  //         </div>
  //         <div className={`ml-36 flex-col items-center ${styles['section_5']} ${styles['equal-division-item_2']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`mt-20 ${styles['font_4']}`}>软件定制化</span>
  //         </div>
  //         <div className={`ml-36 flex-col items-center ${styles['section_5']} ${styles['equal-division-item_2']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`mt-20 ${styles['font_4']}`}>多种行业专家库</span>
  //         </div>
  //       </div>
  //     </div>
  //     <div className={`flex-row justify-center relative ${styles['section_6']}`}>
  //       <span className={`self-start ${styles['font_3']} ${styles['pos_2']}`}>定制化服务</span>
  //       <div className={`flex-row ${styles['equal-division']}`}>
  //         <div className={`flex-col items-center ${styles['equal-division-item']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`mt-20 ${styles['font_4']} ${styles['text_15']}`}>协作</span>
  //         </div>
  //         <div className={`ml-36 flex-col items-center ${styles['equal-division-item']}`}>
  //           <img
  //             className={`shrink-0 ${styles['image_5']}`}
  //             src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=ec89749810c1f6d90ccca444ba989861.png"
  //           />
  //           <span className={`${styles['font_4']} ${styles['text_16']} ${styles['mt-21']}`}>行业</span>
  //         </div>
  //       </div>
  //     </div>
  //         <div className={`flex-row justify-center relative ${styles['section_7']}`}>
  //           <div className={`flex-col items-center self-center ${styles['pos_3']}`}>
  //             <img
  //               className={`${styles['image_6']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=2447f5886400716b50878cf72a75b67e.png"
  //             />
  //             <img
  //               className={`${styles['image_7']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=7a20a788df1f233238a4c5e76ecf20d1.png"
  //             />
  //           </div>
  //           <div className={`flex-col self-start ${styles['group_10']}`}>
  //             <span className={`self-stretch ${styles['font_5']}`}>
  //               公司地址：上海市普陀区陕西北路1388号银座企业中心 1816室
  //               <br />
  //             </span>
  //             <span className={`self-start ${styles['font_5']} ${styles['text_18']} ${styles['mt-29']}`}>
  //               电话：86-XXXXXXXXXX
  //             </span>
  //             <span className={`self-start ${styles['font_5']} ${styles['mt-29']}`}>传真：XXXXXXXX</span>
  //           </div>
  //           <div
  //             className={`flex-col justify-start items-center self-start ${styles['text-wrapper']} ${styles['pos_4']}`}
  //           >
  //             <span className={`${styles['font_3']} ${styles['text_17']}`}>二维码</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['text-wrapper_2']}`}>
  //           <span className={`${styles['font_3']} ${styles['text_19']}`}><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021002642号-2</a></span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Product_main;