import React, { useState } from "react";
import { Link } from 'react-router-dom';
import styles from './navbar.module.css';

function Navbar() {
    const [topbar, setTopbar] = useState(false);
    const [dropdown, setDropdown] = useState(false);



    const handleDropdown = () => {
        setDropdown(!dropdown);
    }

    const handleMouseLeave = () => {
        setDropdown(false);
    }

    const subMenues = [
        {
            title: '公司信息',
            webURL: '/about_intro',
        },
        {
            title: '招贤纳士',
            webURL: '/about_invite',
        },
        {
            title: '联系我们',
            webURL: '/about_contact',
        }
    ];


    return (
        <nav className={`flex-row justify-between items-center ${styles['header']}`}>
            <div className={`flex-row items-center`}>
                <img
                    className={`${styles['image']}`}
                    src={require("../pictures/navbar/logo.png").default}
                />
                <img
                    className={`${styles['image_2']}`}
                    src={require("../pictures/navbar/logo2.png").default}
                />
            </div>
            <div className={`flex-row items-center ${styles['group']}`}>
                <div className={`flex-col justify-start items-center ${styles['section']}`}>

                    <div className={`${styles['group_2']}`}>
                        <Link to='/' className={`${styles['nav_link']}`}>
                            <span className={`${styles['font']} ${styles['text']}`}>主页</span>
                            <span className={`${styles['font_2']} ${styles['text_5']}`}>HOMEPAGE</span>

                        </Link>
                    </div>

                </div>
                <div className={`flex-col justify-start items-center ${styles['section']}`}>
                    <div className={`${styles['group_3']}`}>
                        <Link to='/product_main' className={`${styles['nav_link']}`}>
                            <span className={`${styles['font']} ${styles['text_2']}`}>服务</span>
                            <span className={`${styles['font_2']} ${styles['text_6']}`}>SERVICE</span>
                        </Link>
                    </div>
                </div>
                <div className={`flex-col justify-start items-center ${styles['section']}`}>
                    <div className={`${styles['group_4']}`}>
                        <Link to='/cases_device' className={`${styles['nav_link']}`}>
                            <span className={`${styles['font']} ${styles['text_3']}`}>产品 </span>
                            <span className={`${styles['font_2']} ${styles['text_7']}`}>PRODUCT</span>
                        </Link>
                    </div>
                </div>
                <div className={`flex-col justify-start items-center ${styles['section']}`}>
                    <div className={`${styles['group_5']}`}>
                        <Link to='/client' className={`${styles['nav_link']}`}>
                            <span className={`${styles['font']} ${styles['text_4']}`}>客户</span>
                            <span className={`${styles['font_2']} ${styles['text_8']}`}>CLIENT</span>
                        </Link>
                    </div>
                </div>
                <div className={`flex-col justify-start items-center ${styles['section']}`} onMouseLeave={handleMouseLeave}>
                    <div onClick={handleDropdown} className={`${styles['group_6']}`}>

                        <a className={`${styles['nav_link']}`}>
                            <span className={`${styles['font']}`}>关于</span>
                            <span className={`${styles['font_2']} ${styles['text_9']}`}>ABOUT</span>
                        </a>
                    </div>
                    {dropdown ? (
                        <ul className={`dropdown ${styles['dropdown']}`}>

                            <li className={`sub-menu ${styles['submenu']} ${styles['font']} ${styles['text_10']}`}>
                                <Link to={subMenues[0].webURL} className={`${styles['nav_link']}`}>
                                    {subMenues[0].title}
                                </Link>
                            </li>

                            <li className={`sub-menu ${styles['submenu']} ${styles['font']} ${styles['text_10']}`}>
                                <Link to={subMenues[1].webURL} className={`${styles['nav_link']}`}>{subMenues[1].title}</Link>
                            </li>
                            <li className={`sub-menu ${styles['submenu']} ${styles['font']} ${styles['text_10']}`}>
                                <Link to={subMenues[2].webURL} className={`${styles['nav_link']}`}>{subMenues[2].title}</Link>
                            </li>
                        </ul>
                    ) : null}
                </div>
                <img
                    className={`${styles['image_3']}`}
                    src={require("../pictures/navbar/nav_search.png").default}
                />
            </div>
        </nav>
    )
}

export default Navbar;