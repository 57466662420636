import React, { useState, useEffect, Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './cases_device.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Cases_device extends Component {

  state = {
    name: '产品-设备',
    code: '3.1',
  }

  render() {
    let state = this.state;

    const deviceList = [
      {
        pictureURL: require("../../pictures/cases/光学柱体测量台.png").default,
        name: '光学柱体测量台',
        docPath: '../../pictures/cases/光学柱体测量台.png'
      },
      {
        pictureURL: require("../../pictures/cases/凝胶时间测量台.png").default,
        name: '凝胶时间测量台',
        docPath: '../../pictures/cases/凝胶时间测量台.png'
      },
      {
        pictureURL: require("../../pictures/cases/超声离子洁净处理设备.png").default,
        name: '超声离子洁净处理设备',
        docPath: '../../pictures/cases/超声离子洁净处理设备.png'
      },
      {
        pictureURL: require("../../pictures/cases/default.png").default,
        name: '测试',
        docPath: '../../pictures/cases/default.png'
      },
    ]

    const chunkSize = 3;

    const chunkedArray = Array.from({ length: Math.ceil(deviceList.length / chunkSize) }, (_, index) =>
      deviceList.slice(index * chunkSize, (index + 1) * chunkSize)
    );



    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/cases/产品素材.png").default}
          />
          <div className={`flex-row justify-between ${styles['group_8']}`}>
            <div className={`flex-col justify-start ${styles['text-wrapper']}`}>
              <span className={`${styles['text_11']}`}>产品案例</span>
            </div>
            <div className={`flex-row items-center ${styles['section_2']}`}>
              <span className={`${styles['font_3']} ${styles['text_11']}`}>
                <input type='search' width={'15rem'} placeholder='输入查找对象' />
              </span>
              <button className={`${styles['button_search']}`}>搜索</button>

            </div>
          </div>
          <div className={`flex-row ${styles['section_3']}`}>
            <div className={`flex-col shrink-0 self-start`}>
              <div className={`flex-row items-center ${styles['section_7']}`}>
                <div className={`flex-col justify-start shrink-0 ${styles['text-wrapper_2']}`}>
                  <span className={`${styles['font_3']} ${styles['text_13']}`}>设备</span>
                </div>
                <span className={`${styles['font_4']} ${styles['ml-161']}`}>&gt;</span>
              </div>
              <div className={`flex-row items-center ${styles['section_4']}`}>
                <Link to='/cases_internet' style={{ textDecoration: 'none' }}>
                  <span className={`${styles['font_3']} ${styles['text_13']}`}>物联网</span>
                </Link>
                <span className={`${styles['font_4']} ${styles['ml-137']}`}>&gt;</span>
              </div>
              <div className={`flex-row items-center ${styles['section_4']}`}>

                <Link to='/cases_device' style={{ textDecoration: 'none' }}>
                  <span className={`${styles['font_3']} ${styles['text_15']}`}>数字化</span>
                </Link>

                <span className={`${styles['font_4']} ${styles['ml-128']}`}>&gt;</span>
              </div>
            </div>
            <div className={`flex-col justify-start items-start flex-1 ${styles['section_5']}`}>
              {chunkedArray.map((chunk, index) => (
                <div className={`flex-row`}>
                  {chunk.map((item, i) => (
                    <div className={`flex-col ${styles['section_6']}`}>
                      <img
                        className={`shrink-0 ${styles['image_6']}`}
                        src={item.pictureURL}
                      />
                      <div className={`flex-col items-center ${styles['mt-47']}`}>
                        <span className={`${styles['text_14']}`}>{item.name}</span>
                        <div className={`flex-col justify-start items-center ${styles['button']} ${styles['mt-27']}`}>
                          <a href={item.docPath} style={{ textDecoration: 'none' }} className={`${styles['font_3']} ${styles['text_16']}`}>下载案例</a>
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              ))}


            </div>
          </div>
        </div>

      </Layout >
    )
  }

  // return (
  //   <div className={`flex-col ${styles['page']}`}>
  //     <div className={`flex-row justify-between items-center ${styles['header']}`}>
  //       <div className={`flex-row items-center`}>
  //         <img
  //           className={`${styles['image']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=b030080fb52becab53f8e4588f46bbb2.png"
  //         />
  //         <img
  //           className={`${styles['image_2']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=13f95824b26ebe209983eddfcd39dd44.png"
  //         />
  //       </div>
  //       <div className={`flex-row items-center ${styles['group']}`}>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_2']}`}>
  //             <span className={`${styles['font']} ${styles['text']}`}>主页</span>
  //             <span className={`${styles['font_2']} ${styles['text_6']}`}>HOMEPAGE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_3']}`}>
  //             <span className={`${styles['font']} ${styles['text_2']}`}>服务</span>
  //             <span className={`${styles['font_2']} ${styles['text_7']}`}>SERVICE</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_4']}`}>
  //             <span className={`${styles['font']} ${styles['text_3']}`}>产品 </span>
  //             <span className={`${styles['font_2']} ${styles['text_8']}`}>PRODUCT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_5']}`}>
  //             <span className={`${styles['font']} ${styles['text_4']}`}>客户</span>
  //             <span className={`${styles['font_2']} ${styles['text_9']}`}>CLIENT</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['section']}`}>
  //           <div className={`${styles['group_6']}`}>
  //             <span className={`${styles['font']} ${styles['text_5']}`}>关于</span>
  //             <span className={`${styles['font_2']} ${styles['text_10']}`}>ABOUT</span>
  //           </div>
  //         </div>
  //         <img
  //           className={`${styles['image_3']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=0b92780d7e5c0dd988a7a1cc155133e7.png"
  //         />
  //       </div>
  //     </div>
  // <div className={`flex-col flex-1 ${styles['group_7']}`}>
  //   <img
  //     className={`${styles['image_4']}`}
  //     src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=522b0cbc8f7baa9b8616d1728041649d.png"
  //   />
  //   <div className={`flex-col ${styles['group_8']}`}>
  //     <div className={`flex-row justify-between ${styles['group_9']}`}>
  //       <div className={`flex-col justify-start ${styles['text-wrapper']}`}>
  //         <span className={`${styles['text_11']}`}>产品案例</span>
  //       </div>
  //       <div className={`flex-row items-center ${styles['input']} ${styles['section_2']}`}>
  //         <span className={`${styles['font_3']} ${styles['text_12']}`}>输入查找对象</span>
  //         <img
  //           className={`ml-48 shrink-0 ${styles['image_5']}`}
  //           src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=229f52dfe9e21788af257003dd811a71.png"
  //         />
  //       </div>
  //     </div>
  //     <div className={`flex-row ${styles['section_3']}`}>
  //       <div className={`flex-col shrink-0 self-start ${styles['group_10']}`}>
  //         <div className={`flex-row items-center ${styles['section_5']}`}>
  //           <div className={`flex-col justify-start items-end shrink-0 ${styles['text-wrapper_2']}`}>
  //             <span className={`${styles['font_3']} ${styles['text_13']}`}>设备</span>
  //           </div>
  //           <span className={`${styles['font_4']} ${styles['ml-152']}`}>></span>
  //         </div>
  //         <div className={`flex-row items-center ${styles['section_6']}`}>
  //           <span className={`${styles['font_3']} ${styles['text_14']}`}>物联网</span>
  //           <span className={`${styles['font_4']} ${styles['ml-137']}`}>></span>
  //         </div>
  //         <div className={`flex-row items-center ${styles['section_6']}`}>
  //           <span className={`${styles['font_3']} ${styles['text_15']}`}>数字化</span>
  //           <span className={`${styles['font_4']} ${styles['ml-137']}`}>></span>
  //         </div>
  //       </div>
  //       <div className={`flex-col justify-start items-center flex-1 ${styles['section_4']}`}>
  //         <div className={`flex-row`}>
  //           <div className={`flex-col items-center ${styles['equal-division-item']}`}>
  //             <img
  //               className={`shrink-0 ${styles['image_6']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=b60ca2d2951635f0b0fbf3af57e021dd.png"
  //             />
  //             <span className={`${styles['font_5']}`}>光学柱体测量台</span>
  //             <div
  //               className={`flex-col justify-start items-center ${styles['text-wrapper_3']} ${styles['button']}`}
  //             >
  //               <span className={`${styles['font_6']}`}>下载案例</span>
  //             </div>
  //           </div>
  //           <div className={`flex-col items-center ${styles['equal-division-item']} ${styles['ml-53']}`}>
  //             <img
  //               className={`shrink-0 ${styles['image_6']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=a63183354f1f43695ba8eb6d5d557184.png"
  //             />
  //             <span className={`${styles['font_5']}`}>凝胶时间测量台</span>
  //             <div className={`flex-col justify-start items-center ${styles['button']}`}>
  //               <span className={`${styles['font_6']}`}>下载案例</span>
  //             </div>
  //           </div>
  //           <div className={`flex-col items-center ${styles['equal-division-item']} ${styles['ml-53']}`}>
  //             <img
  //               className={`shrink-0 ${styles['image_6']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=f9f59538432ec487381429f11823f33a.png"
  //             />
  //             <span className={`${styles['font_5']}`}>室内型网关</span>
  //             <div className={`flex-col justify-start items-center ${styles['button']}`}>
  //               <span className={`${styles['font_6']}`}>下载案例</span>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //       <div className={`flex-col ${styles['group_11']}`}>
  //         <div className={`flex-row justify-center relative ${styles['section_7']}`}>
  //           <div className={`flex-col items-center self-center ${styles['pos']}`}>
  //             <img
  //               className={`${styles['image_7']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=2447f5886400716b50878cf72a75b67e.png"
  //             />
  //             <img
  //               className={`${styles['image_8']}`}
  //               src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=7a20a788df1f233238a4c5e76ecf20d1.png"
  //             />
  //           </div>
  //           <div className={`flex-col self-start ${styles['group_12']}`}>
  //             <span className={`self-stretch ${styles['font_7']}`}>
  //               公司地址：上海市普陀区陕西北路1388号银座企业中心 1816室
  //               <br />
  //             </span>
  //             <span className={`self-start ${styles['font_7']} ${styles['text_16']} ${styles['mt-29']}`}>
  //               电话：86-XXXXXXXXXX
  //             </span>
  //             <span className={`self-start ${styles['font_7']} ${styles['mt-29']}`}>传真：XXXXXXXX</span>
  //           </div>
  //           <div
  //             className={`flex-col justify-start items-center self-start ${styles['text-wrapper_4']} ${styles['pos_2']}`}
  //           >
  //             <span className={`${styles['font_3']}`}>二维码</span>
  //           </div>
  //         </div>
  //         <div className={`flex-col justify-start items-center ${styles['text-wrapper_5']}`}>
  //           <span className={`${styles['font_3']} ${styles['text_17']}`}><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021002642号-2</a></span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Cases_device;