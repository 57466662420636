
import React, { Component } from 'react';
import styles from './product_digital1.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Product_digital1 extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '数据可视化',
    code: 2.3,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
        <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
        <div className={`flex-col ${styles['group_8']}`}>
          <div className={`flex-col items-center`}>
            <span className={`${styles['text_10']}`}>数据可视化</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`mt-36 flex-col`}>
            <div className={`flex-col ${styles['section_3']}`}>
              <span className={`self-stretch ${styles['font_3']}`}> 数据可视化过程包括数据采集，数据可视化，反馈控制三个阶段，具体内容如下：<br /></span>
              <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>a. 采集车间、产线数据。包括现有数据的采集（例如PLC、设备运行数据等）以及在老设备上加装传感器后的数据采集。<br /></span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>b. 数据传输及汇聚。通过无线及有线网络将采集到的数据传输到云端进行存储。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 用户利用PC或移动终端查看可视化数据。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>d. 根据用户要求自动或手动实现对生产设备的反馈控制。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>e. 根据用户要求实现定制化MES功能。</span>
            </div>
            <div className={`flex-row justify-between ${styles['section_4']}`}>
              <Link to='/product_digital4' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&lt;</span>
              </Link>
              <div className={`flex-row ${styles['equal-division']}`}>
                <div className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/数据可视化.png").default} />
                  <span className={`${styles['font_5']} ${styles['text_11']}`}>数据可视化</span>
                  <div className={`shrink-0 ${styles['section_5']}`}></div>
                </div>
                <Link to='/product_digital2' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/数据打通.jpg").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>数据打通</span>
                </Link>
                <Link to='/product_digital3' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/软件定制化.jpeg").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>软件定制化</span>
                </Link>
                <Link to='/product_digital4' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/多种行业专家库.png").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>多种行业专家库</span>
                </Link>
              </div>
              <Link to='/product_digital2' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </Layout>
    )
  }
}
    
  export default Product_digital1;
  