import React from "react";
import styles from './foot.module.css';

function Foot() {
    return (
        <div className={`flex-col ${styles['group_13']}`}>
         <div className={`flex-row justify-center relative ${styles['section_3']}`}>
           <div className={`flex-col items-center self-center ${styles['pos_2']}`}>
             <img
                className={`${styles['image_8']}`}
                src={require("../pictures/foot/foot_logo.png").default}
              />
              <img
                className={`${styles['image_9']}`}
                src={require("../pictures/foot/foot_logo2.png").default}
              />
            </div>
            <div className={`flex-col self-start ${styles['group_14']}`}>
              <span className={`self-stretch ${styles['font_6']} ${styles['text_15']}`}>
                公司地址：上海市普陀区陕西北路1388号银座企业中心 1816室
                <br />
              </span>
              <span className={`self-start ${styles['font_6']} ${styles['text_17']} ${styles['mt-29']}`}>
                电话：021-61234357/61234358
              </span>
              <span className={`self-start ${styles['font_6']} ${styles['text_18']} ${styles['mt-29']}`}>
                邮箱：
				<a href="mailto:hr@wuzhong-tech.com"><span className={`${styles['font_3']}`}>hr@wuzhong-tech.com</span></a>
              </span>
            </div>
            <div
              className={`flex-col justify-start items-center self-start ${styles['pos_3']}`}
            >
              <img
                className={`${styles['image_8']}`}
                src={require("../pictures/foot/二维码.png").default}
              />
            </div>
          </div>
          <div className={`flex-col justify-start items-center ${styles['text-wrapper_5']}`}>
            <span className={`${styles['font_4']} ${styles['text_19']}`}><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021002642号-2</a></span>
          </div>
        </div>
    )
}

export default Foot;