import React, {Component} from "react";
import Navbar from '../components/navbar';
import Foot from '../components/foot';


class Layout extends Component{
    render(){
        let {children} = this.props;
        return (
            <div className="layout">
                <Navbar/>
                <div className="contain">
                    {children}
                </div>
                <Foot/>
            </div>
        )
    }
}

export default Layout;