
import React, { Component } from 'react';
import styles from './product_customize1.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class product_customize1 extends Component {

  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '协作',
    code: 2.7,
  }

  render() {
    let state = this.state;
    console.log(this.props.state);
    return (
      <Layout>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
        <img className={`${styles['image_4']}`} src={require("../../pictures/product_main/服务素材.jpeg").default} />
        <div className={`flex-col ${styles['group_8']}`}>
          <div className={`flex-col items-center`}>
            <span className={`${styles['text_10']}`}>协作</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`mt-36 flex-col`}>
            <div className={`flex-col ${styles['section_3']}`}>
              <span className={`self-stretch ${styles['font_3']}`}> a. 定制化方案的完成需要伍中科技的专家与客户相关部门人员进行协作，从而保证方案定位准确，有的放矢。<br /></span>
              <span className={`self-stretch ${styles['font_3']} ${styles['mt-23']}`}>b. 聚焦客户的提出的问题，理解客户的业务流程和逻辑，利用专业经验为客户拓展开发新的价值。<br /></span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>c. 为解决客户提出的问题，提供机械、电子电力、软硬件等综合解决方案。</span>
              <span className={`self-start ${styles['font_3']} ${styles['mt-23']}`}>d. 跨学科的技术融合能力可以给客户带来超出传统的MES范畴以外的价值。</span>
            </div>
            <div className={`flex-row justify-between ${styles['section_4']}`}>
              <Link to='/product_customize2' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&lt;</span>
              </Link>
              <div className={`flex-row ${styles['equal-division']}`}>
                <div className={`flex-col items-center ${styles['equal-division-item']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/协作.jpeg").default} />
                  <span className={`${styles['font_5']} ${styles['text_11']}`}>协作</span>
                  <div className={`shrink-0 ${styles['section_5']}`}></div>
                </div>
                <Link to='/product_customize2' style={{textDecoration: 'none'}} className={`ml-36 flex-col items-center ${styles['equal-division-item_2']}`}>
                  <img className={`shrink-0 ${styles['image_5']}`} src={require("../../pictures/product_main/行业.png").default} />
                  <span className={`mt-20 ${styles['font_5']}`}>行业</span>
                </Link>
                
              </div>
              <Link to='/product_customize2' style={{textDecoration: 'none'}} className={`flex-col justify-start items-center self-start ${styles['text-wrapper']}`}>
                <span className={`${styles['font_4']}`}>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        </div>
      </Layout>
    )
  }
}
    
  export default product_customize1;
  