import React, { useState, useEffect, Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './about_invite.module.css';
import Layout from '../../common/layout';

class About_invite extends Component {
  // const history = useHistory();

  // const [data, setData] = useState({});

  state = {
    name: '招贤纳士',
    code: 5.2,
  };

  render() {
    let state = this.state;
    return (
      <Layout {...state}>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/about/招贤纳士.png").default}
          />
          <div className={`flex-col items-center ${styles['group_8']}`}>
            <span className={`${styles['text_10']}`}>招纳贤士</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`flex-col justify-start ${styles['group_10']}`}>
            <div className={`${styles['group_9']}`}>
              <span className={`${styles['font_3']}`}>
                我们拥抱新事物。我们积极探索新的公司组织模式。我们需要跨学科、跨行业的团队来解决复杂的客户需求。
                <br />
              </span>
              <span className={`${styles['font_3']}`}>
                我们提供两种入伙模式：
                <br />
              </span>
              <span className={`${styles['font_3']}`}>
                1. 普通员工模式
                <br />
              </span>
              <span className={`${styles['font_3']}`}>
                &nbsp;您可以提出您的要求，包括薪资、工作时间、工作地点等并证明您的工作能力，然后我们和您协商签订劳动协议。
                <br />
              </span>
              <span className={`${styles['font_3']}`}>
                2. 合伙人模式
                <br />
              </span>
              <span className={`${styles['font_3']}`}>
                &nbsp;在证明您的工作能力后，我们基于项目和您协商签订合伙人协议。
                <br />
              </span>
              <span className={`${styles['font_3']}`}>请将您的简历发送到</span>
			  <a href="mailto:hr@wuzhong-tech.com"><span className={`${styles['font_3']}`}>hr@wuzhong-tech.com</span></a>
			  <span className={`${styles['font_3']}`}>并注明您选择的入伙模式。</span>
            </div>
          </div>
        </div>
      </Layout>
    )
  }


}

export default About_invite;