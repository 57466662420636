import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './cases_login.module.css';

export default function Cases_login(props) {
  const history = useHistory();

  const [data, setData] = useState({});

  return (
    <div className={`flex-col justify-start relative ${styles['page']}`}>
      <div className={`${styles['section']}`}></div>
      <div className={`flex-col ${styles['section_2']} ${styles['pos']}`}>
        <img
          className={`self-start ${styles['image']}`}
          src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=8d3bbca78235e573e08a8eddc52e683d.png"
        />
        <div className={`flex-col self-stretch ${styles['group']} ${styles['mt-43']}`}>
          <div className={`flex-row items-end`}>
            <div className={`flex-col items-center shrink-0 ${styles['group_2']}`}>
              <img
                className={`${styles['image_2']}`}
                src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=f597abe4ee89af9da1f4ad287b23d305.png"
              />
              <img
                className={`${styles['image_4']}`}
                src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=c6762596f02517e1c61e95d9c0ebde37.png"
              />
            </div>
            <div className={`flex-col flex-1 ${styles['ml-47']}`}>
              <span className={`self-center ${styles['text']}`}>用户登录</span>
              <div className={`flex-row items-center self-stretch ${styles['section_3']} ${styles['view']}`}>
                <img
                  className={`${styles['image_3']}`}
                  src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=66d7eec5866409b5a58913f3f17fd2a2.png"
                />
                <span className={`${styles['font']} ${styles['text_2']} ${styles['ml-31']}`}>请输入用户名或邮箱</span>
              </div>
              <div className={`flex-row items-center self-stretch ${styles['section_3']} ${styles['view_2']}`}>
                <img
                  className={`${styles['image_3']}`}
                  src="https://ide.code.fun/api/image?token=6594c6f2bddbb70011868447&name=d229cd53eed2aeb10efabb7a0deb59ee.png"
                />
                <span className={`${styles['font']} ${styles['text_3']} ${styles['ml-31']}`}>请输入密码</span>
              </div>
              <div className={`flex-col justify-start items-center ${styles['button']} ${styles['text-wrapper']}`}>
                <span className={`${styles['text_4']}`}>登录</span>
              </div>
            </div>
          </div>
          <div className={`flex-row justify-end ${styles['group_3']} ${styles['mt-23']}`}>
            <span className={`${styles['font_2']}`}>用户注册</span>
            <span className={`ml-30 ${styles['font_2']} ${styles['text_5']}`}>忘记密码</span>
          </div>
        </div>
      </div>
    </div>
  );
}

Cases_login.defaultProps = {};