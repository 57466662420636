import React, { useState, useEffect, Component } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './homepage.module.css';
import Layout from '../../common/layout';
import { Link } from 'react-router-dom';

class Homepage extends Component {


  // const [data, setData] = useState({});

  state = {
    name: '首页',
    code: 1,
    States: [true,false,false]
  };

  render() {
    let state = this.state;
    // const history = useHistory();

    const homeList = [
      {
        key: 0,
        title: '自动化升级',
        subtitle: '自动化改造与升级',
        pictureURL: require("../../pictures/homepage/自动化.jpeg").default,
        intro: '自动化改造主要针对客户已有的自动化水平较低的生产设备。自动化升级主要针对客户已有的自动化水平较高的生产设备。自动化改造和升级是在现有的自动化基础上，进一步完善，进一步提高，为数字化制造打好物质基础。',
        state: this.state.States[0],
        jumpto: '/product_automation',
      },
      {
        key: 1,
        title: '数字化改造',
        subtitle: '数字化改造方案',
        pictureURL: require("../../pictures/homepage/数字化.jpeg").default,
        intro: '数字化改造旨在通过数据采集、数据可视化、反馈控制等手段，使用数据打通、软件定制化等方式对车间、产线的数据进行采集与处理，从而实现客户对产业升级的需求。',
        state: this.state.States[1],
        jumpto: '/product_digital3',
      },
      {
        key: 2,
        title: '定制化服务',
        subtitle: '定制化服务',
        pictureURL: require("../../pictures/homepage/定制化.jpeg").default,
        intro: '定制化方案涵盖了包括机械、电子电力、软件、算法、化学、光学、声学等学科，通过伍中科技的专家与客户相关部门人员的交流与协作，基于已有的框架与模块，可以为客户提供专业化、定制化的方案，并快速实施对应项目。',
        state: this.state.States[2],
        jumpto: '/product_customize1',
      },
    ]

    const handleClick = (index) => {
      console.log(index);
      if(index === 0) {
        this.setState({States: [true,false,false]})
      } else if (index ===1) {
        this.setState({States: [false,true,false]})
      } else if (index === 2) {
        this.setState({States: [false,false,true]})
      }
      
      
    }

    return (

      <Layout {...state}>
        <div className={`flex-col flex-1 ${styles['group_7']}`}>
          <img
            className={`${styles['image_4']}`}
            src={require("../../pictures/homepage/首页素材.jpeg").default}
          />
          <div className={`flex-col items-center ${styles['group_8']}`}>
            <span className={`${styles['font_3']} ${styles['text_10']}`}>产品与服务</span>
            <div className={`${styles['section_2']} ${styles['mt-5']}`}></div>
          </div>
          <div className={`flex-col ${styles['group_9']}`}>
            <div className={`flex-row`}>
              {homeList.map((item, index) => (
                
                item.state ? (
                  <div className={`flex-col justify-start relative ${styles['group_10']}`}>
                    <div className={`flex-col justify-start items-center ${styles['text-wrapper']}`}>
                      <span className={`${styles['font_3']} ${styles['text_11']}`}>{item.title}</span>
                    </div>
                    <img
                      className={`${styles['image_5']} ${styles['pos']}`}
                      src={require("../../pictures/homepage/trangle.png").default}
                    />
                  </div>
                ) : (
                  <div onClick={() => handleClick(index)} className={`flex-col justify-start items-center self-start ${styles['text-wrapper_2']}`}>
                    <span className={`${styles['font_3']}`}>{item.title}</span>
                  </div>
                )
                
              ))}
            </div>
            {homeList.map((item, index) => (
              item.state &&
              <div key={item.key} className={`flex-row ${styles['mt-23']}`}>
                <img
                  className={`flex-1 ${styles['image_6']}`}
                  src={item.pictureURL}
                />
                <div className={`ml-24 flex-col shrink-0 self-start ${styles['group_11']}`}>
                  <span className={`self-start ${styles['text_12']}`}>{item.subtitle}</span>
                  <span className={`self-stretch ${styles['text_13']}`}>
                    {item.intro}
                  </span>
                  <div className={`flex-col justify-start items-center ${styles['button']} ${styles['text-wrapper_3']}`}>
                    <Link to={item.jumpto} style={{textDecoration: 'none'}} className={`${styles['font_4']} ${styles['text_14']}`}>了解更多</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={`flex-row ${styles['equal-division']}`}>
            <a href='/cases_device' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['group_12']} ${styles['equal-division-item']}`}>
              <img
                className={`${styles['image_7']}`}
                src={require("../../pictures/homepage/产品案例.png").default}
              />
              <span className={`mt-44 ${styles['font_5']}`}>产品案例</span>
            </a>
            <a href='/client' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['group_12']} ${styles['equal-division-item']}`}>
              <img
                className={`${styles['image_7']}`}
                src={require("../../pictures/homepage/客户登录.png").default}
              />
              <span className={`mt-44 ${styles['font_5']}`}>客户登录</span>
            </a>
            <a href='/about_intro' style={{textDecoration: 'none'}} className={`flex-col items-center ${styles['group_12']} ${styles['equal-division-item']}`}>
              <img
                className={`${styles['image_7']}`}
                src={require("../../pictures/homepage/关于我们.png").default}
              />
              <span className={`mt-44 ${styles['font_5']}`}>关于我们</span>
            </a>
          </div>
        </div>
      </Layout>

    )
  }
  
}

//Homepage.defaultProps = {};

export default Homepage;